import {MetaBlock} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import {pushToDataLayer} from '@/utils/analytics'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {ArticleLister} from '@/_new-code/products/flexible-web-toolkit/components/article-lister'

export type ArticleListerContentItem = IContentItem<{
	title: Elements.TextElement
	image: Elements.AssetsElement
	text: Elements.RichTextElement
	metaText: Elements.TextElement
	buttonUrl: Elements.TextElement
	buttonText: Elements.TextElement
}>

export const ArticleListerBlock: Block<ArticleListerContentItem> = ({
	block,
	...context
}) => {
	const image = block.elements.image[0]
	return (
		<div className={`container-wide px-8 ${defaultMargins}`}>
			<ArticleLister
				description={
					<RichTextRenderer
						data-kontent-element-codename="text"
						element={block.elements.text}
						{...context}
					/>
				}
				imageMetablock={
					<MetaBlock metaText={block.elements.metaText} />
				}
				imageUrl={image ? image.url : null}
				title={
					<span data-kontent-element-codename="title">
						{block.elements.title}
					</span>
				}
			>
				{block.elements.buttonText ? (
					<Link
						href={block.elements.buttonUrl}
						legacyBehavior
						passHref
					>
						<Button
							data-kontent-element-codename="button_text"
							onClick={() => {
								pushToDataLayer({
									event: 'cta_click',
									cta_name: block.elements.buttonText,
									cta_category: 'CTA_Button',
								})
							}}
						>
							{block.elements.buttonText}
						</Button>
					</Link>
				) : null}
			</ArticleLister>
		</div>
	)
}
