import {InteractiveList as CInteractiveList} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {JSX} from 'react'
import type {VideoBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/video-block'
import type {WistiaContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/wistia'
import type {ImageContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/image'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'

type InteractiveListItemContentItem = IContentItem<{
	itemTitle: Elements.TextElement
	imageOverlay: Elements.MultipleChoiceElement
	videoImage: Elements.LinkedItemsElement<
		VideoBlockContentItem | WistiaContentItem | ImageContentItem
	>
}>

export type InteractiveListContentItem = IContentItem<{
	title: Elements.TextElement
	headingDescription: Elements.TextElement
	footerDescription: Elements.TextElement
	interactiveListItems: Elements.LinkedItemsElement<InteractiveListItemContentItem>
}>

export const InteractiveListBlock: Block<InteractiveListContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	const getMediaCard = (
		item: Tersed<VideoBlockContentItem> | Tersed<WistiaContentItem>
	): JSX.Element => {
		return (
			<div>
				<BlockMapper
					blocks={[item]}
					className="*:w-full"
					{...context}
				/>
			</div>
		)
	}

	const list = block.elements.interactiveListItems.map((item) => {
		if (
			item.elements.videoImage[0] &&
			(item.elements.videoImage[0]?.system.type === 'video_block' ||
				item.elements.videoImage[0]?.system.type === 'wistia')
		) {
			return {
				item_title: item.elements.itemTitle,
				videoComponent: getMediaCard(
					item.elements.videoImage[0] as
						| Tersed<VideoBlockContentItem>
						| Tersed<WistiaContentItem>
				),
			}
		}

		const videoImage = item.elements.videoImage[0] as
			| Tersed<ImageContentItem>
			| undefined

		return {
			imageUrl: videoImage?.elements.image[0]?.url,
			altText: videoImage?.elements.image[0]?.description,
			height: 334,
			width: 600,
			item_title: item.elements.itemTitle,
			overLay: item.elements.imageOverlay[0]?.codename === 'yes',
		}
	})

	return (
		<div className="pt-4 md:pb-8 md:pt-8 lg:pt-16">
			<div
				className="container-wide"
				data-kontent-element-codename="interactive_list"
			>
				<CInteractiveList
					footerDescription={block.elements.footerDescription}
					headingDescription={block.elements.headingDescription}
					list={list}
					title={block.elements.title}
				/>
			</div>
		</div>
	)
}
