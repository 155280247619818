import type {Dispatch, SetStateAction, JSX} from 'react'
import {useEffect} from 'react'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import type {
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import type {HeaderState} from '../helpers'
import {PrimaryButtons} from './buttons'
import {ShowSearchButton} from './show-search-button'

export const ToggleNav = ({
	globalConfig,
	headerState,
	setHeaderState,
	toggleSearchPopup,
}: {
	globalConfig: Tersed<GlobalConfigContentItem>
	headerState: HeaderState
	setHeaderState: Dispatch<SetStateAction<HeaderState>>
	toggleSearchPopup: () => void
}): JSX.Element => {
	const headerConfig = globalConfig.elements.headerConfig[0]

	const {isMobile, isTablet} = useWindowSize()

	const toggleNav = (): void => {
		setHeaderState((currentState) => ({
			...currentState,
			navVisible: !currentState.navVisible,
		}))
	}

	useEffect(() => {
		if (isMobile || isTablet) {
			document.body.style.overflow = headerState.navVisible
				? 'hidden'
				: 'unset'
		}
	}, [headerState.navVisible, isMobile, isTablet])

	// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: necessary until react types are fixed
	if (!headerConfig) return <></>

	const primaryNavHasLinks = Boolean(headerConfig.elements.links.length)

	const secondaryHeaderConfig = headerConfig.elements.secondaryHeaderConfig[0]
	const secondaryNavHasLinks = Boolean(
		secondaryHeaderConfig?.elements.links.length
	)

	return (
		<>
			<div className="flex flex-row lg:!invisible lg:!hidden">
				<ShowSearchButton
					searchAccessibilityText={
						headerConfig.elements.searchAccessibilityText
					}
					showSearch={
						headerConfig.elements.showSearch[0]?.codename === 'yes'
					}
					toggleSearchPopup={toggleSearchPopup}
				/>

				{primaryNavHasLinks || secondaryNavHasLinks ? (
					<EllipseButton
						as="button"
						className="ml-4 border border-white"
						icon={
							headerState.navVisible
								? ButtonIcons.Close
								: ButtonIcons.Nav
						}
						onClick={() => {
							toggleNav()
						}}
						size="sm"
					/>
				) : null}
			</div>
			<div className="invisible hidden lg:!visible lg:!flex">
				<div className="flex gap-4">
					<PrimaryButtons
						buttonLinks={headerConfig.elements.buttonLinks}
						loginUrl={globalConfig.elements.loginUrl}
						myAccountWrapper={
							headerConfig.elements.myAccountWrapperParent[0]
						}
						variant="inverted"
					/>
				</div>
				<ShowSearchButton
					searchAccessibilityText={
						headerConfig.elements.searchAccessibilityText
					}
					showSearch={
						headerConfig.elements.showSearch[0]?.codename === 'yes'
					}
					toggleSearchPopup={toggleSearchPopup}
				/>
			</div>
		</>
	)
}
