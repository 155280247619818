import {useState, useEffect} from 'react'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {FullWidthCarousel} from '../../components/carousel/full-width-carousel'

export type FullWidthCarouselContentItem = IContentItem<{
	autoplay: Elements.MultipleChoiceElement
	images: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
}>

export const FullWidthCarouselBlock: Block<FullWidthCarouselContentItem> = ({
	block,
}) => {
	const [headerImage, setHeaderImage] = useState<TersedAsset | null>(null)

	const size = useWindowSize() as {isMobile: boolean}

	useEffect(() => {
		const mobileImage = block.elements.mobileImage[0] ?? null
		const backgroundImage = block.elements.images[0] ?? null

		if (size.isMobile && mobileImage) {
			setHeaderImage(mobileImage)
		} else {
			setHeaderImage(backgroundImage)
		}
	}, [block.elements.images, block.elements.mobileImage, size.isMobile])

	return (
		<div data-kontent-element-codename={block.system.codename}>
			<FullWidthCarousel>
				{headerImage ? (
					<Image
						alt={headerImage.description || ''}
						className="max-h-screen w-full object-cover"
						height={headerImage.height || 0}
						key={headerImage.url || ''}
						loader={loadImageFromKontentAI}
						quality={100}
						src={headerImage.url || ''}
						width={headerImage.width || 0}
					/>
				) : null}
			</FullWidthCarousel>
		</div>
	)
}
