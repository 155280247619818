import {Header} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {JSX} from 'react'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type SecondaryHeaderContentItem = IContentItem<{
	title: Elements.TextElement
	theme: Elements.MultipleChoiceElement
	content: Elements.TextElement
	image: Elements.AssetsElement
}>

export const SecondaryHeaderBlock: Block<SecondaryHeaderContentItem> = ({
	block,
}): JSX.Element => {
	return (
		<div data-kontent-element-codename="secondary_header">
			<Header
				light={
					(block.elements.theme[0]?.codename ?? 'light') === 'light'
				}
			>
				{/* TODO: A11y lint: potential for more than one h1 on the page */}
				<h1>{block.elements.title}</h1>
				<p className="pt-6">{block.elements.content}</p>
				{block.elements.image[0] ? (
					// TODO: Use Next.js <Image /> if we're not already; also set height and width to prevent CLS
					<Header.Image
						alt={block.elements.image[0].description || ''}
						src={block.elements.image[0].url}
					/>
				) : null}
			</Header>
		</div>
	)
}
