import type {ReactNode} from 'react'
import {useState} from 'react'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Button} from '@/_new-code/products/flexible-web-toolkit/components/button/button'
import LoadingState from '@/components/Form/LoadingState'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import type {PetIcon} from '@/_new-code/products/your-pet-and-you/models'
import {Icon} from '@/_new-code/products/flexible-web-toolkit/components/common/icon'

export type PetIconFormContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	nextButtonText: Elements.TextElement
	backButtonText: Elements.TextElement
}>

export const PetIconFormBlock: ExtendedBlock<
	PetIconFormContentItem,
	{
		icons: PetIcon[]
		petName: string
		icon: PetIcon
		onSubmit: (imageReference: string) => void
		isLoading: boolean
		onBack: () => void
		isEdit: boolean
		error: ReactNode
	}
> = ({
	block,
	icons,
	petName,
	icon,
	onSubmit,
	isLoading,
	onBack,
	isEdit,
	error,
}) => {
	const [petIcon, setPetIcon] = useState<PetIcon>(icon)

	return (
		<div className="relative">
			<LoadingState isLoading={isLoading} theme="green" />
			<div className="text-center">
				<h3 className="mb-8 break-words font-semibold leading-6">
					{block.elements.title.replace('{{petName}}', petName)}
				</h3>
				<Icon
					className="mx-auto mb-10"
					iconCustomClasses="bg-white w-40 h-40"
					size="none"
				>
					{petIcon.imageUrl || icon.imageUrl ? (
						<div className="relative h-40 w-40">
							<Image
								alt=""
								className="border-3 rounded-full border-pet-green"
								fill
								loader={loadImageFromKontentAI}
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Assertion is valid from null check above component
								src={(petIcon.imageUrl ?? icon.imageUrl)!}
							/>
						</div>
					) : (
						// eslint-disable-next-line @next/next/no-img-element -- empty img element needed
						<img alt="" src="" />
					)}
				</Icon>
				<h4 className="mb-5 break-words text-base font-semibold leading-5">
					{block.elements.description.replace('{{petName}}', petName)}
				</h4>
				<div>
					<div className="grid grid-cols-2 gap-4 md:grid-cols-3">
						{icons.map((emoji) => (
							<button
								className={
									petIcon.id === emoji.id ? 'opacity-50' : ''
								}
								key={emoji.id}
								onClick={() => {
									setPetIcon(emoji)
								}}
								type="button"
							>
								<Icon iconCustomClasses="w-24 h-24" size="none">
									<>
										{emoji.imageUrl ? (
											<div className="relative h-24 w-24">
												<Image
													alt={emoji.id}
													className="h-24 w-24 rounded-full border-2 border-pet-green"
													fill
													loader={
														loadImageFromKontentAI
													}
													src={emoji.imageUrl}
												/>
											</div>
										) : null}
									</>
								</Icon>
							</button>
						))}
					</div>
				</div>
			</div>
			{error ? <div>{error}</div> : null}
			<div className="flex flex-col items-center justify-center gap-4 pt-8 md:flex-row">
				{!isEdit && (
					<Button
						as="button"
						className="w-full flex-1 md:max-w-[245px]"
						onClick={() => {
							onBack()
							pushToDataLayer({
								event: 'cta_click',
								cta_name: block.elements.backButtonText,
								cta_category: 'CTA_Button',
							})
						}}
						type="button"
						variant="inverted_pet_primary_green"
					>
						{block.elements.backButtonText}
					</Button>
				)}
				<Button
					as="button"
					className="w-full flex-1 md:max-w-[245px]"
					onClick={() => {
						pushToDataLayer({
							event: 'cta_click',
							cta_name: block.elements.nextButtonText,
							cta_category: 'CTA_Button',
						})
						onSubmit(petIcon.id)
					}}
					type="button"
					variant="pet_primary_green"
				>
					{block.elements.nextButtonText}
				</Button>
			</div>
		</div>
	)
}
