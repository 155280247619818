import type {ReactElement, ReactNode} from 'react'
import {Children, cloneElement} from 'react'
import {CardImage} from './card-image'

interface T {
	className?: string
	blur?: boolean
	metaBlockHref?: string
}

interface CardProps {
	children: ReactNode
	className?: string
	leadingTitle?: boolean
	title?: ReactNode
	subtitle?: ReactNode
	metaBlock?: ReactElement<T>
	href?: string | null
	imageOnLeft?: boolean
	isFooterCard?: boolean
	hasFooterCardOnMobile?: boolean
	removeBorders?: boolean
}

export const Card = ({
	children = null,
	className = '',
	href = null,
	imageOnLeft = false,
	leadingTitle = false,
	metaBlock,
	removeBorders = false,
	subtitle = '',
	title = '',
	isFooterCard = false,
	hasFooterCardOnMobile = false,
}: CardProps): ReactElement => {
	const childrenArr = Children.toArray(children) as ReactElement<T>[]
	const contentToRender = (
		<>
			{childrenArr.find((item) => {
				return imageOnLeft ? null : item.type === CardImage
			})}
			{!isFooterCard && leadingTitle && title ? (
				<div className="px-6 py-3">
					<h3>{title}</h3>
				</div>
			) : null}
			{isFooterCard ? (
				<h3 className="w-full bg-gray-100 text-center text-lg md:py-1">
					{title}
				</h3>
			) : null}
			<div
				className={`${
					removeBorders && !isFooterCard && 'content py-4'
				} ${
					isFooterCard && removeBorders
						? 'md:flex-col lg:flex-row'
						: 'flex-grow px-5 py-5 md:px-6'
				} flex flex-col bg-white sm:flex-row`}
			>
				{imageOnLeft ? (
					<div className={isFooterCard ? 'h-40 w-full' : 'mr-4'}>
						{childrenArr.find((item) => {
							return item.type === CardImage
						})}
					</div>
				) : null}
				<div
					className={`flex h-full w-full flex-col bg-white  ${
						removeBorders && !isFooterCard ? 'px-6' : ''
					} ${
						removeBorders &&
						isFooterCard &&
						!hasFooterCardOnMobile &&
						'pb-2'
					} ${
						hasFooterCardOnMobile && 'pb-4'
					} [&>ol]:list-[auto] [&>ul]:list-disc`}
				>
					{!isFooterCard && !leadingTitle && title ? (
						<h3
							className={`mb-2 text-lg lg:text-xl ${
								href ? 'hover:underline' : ''
							}`}
						>
							{title}
						</h3>
					) : null}
					{subtitle ? (
						<p className="content content--no-width-restriction mb-2 text-sm">
							{subtitle}
						</p>
					) : null}
					{childrenArr.filter((item) => {
						return item.type !== CardImage
					})}
				</div>
			</div>
		</>
	)

	return (
		<div
			className={`flex flex-col w-84${
				childrenArr.length > 3 ? 'w-84' : ''
			} ${childrenArr.length === 1 ? 'w-full' : ''}
			${imageOnLeft && isFooterCard ? 'max-w-md' : 'max-w-full'} ${
				isFooterCard ? 'max-h-full shadow-around' : 'shadow-md'
			} ${
				hasFooterCardOnMobile ? 'mx-auto max-h-60' : 'mx-2'
			} overflow-hidden rounded-lg bg-white ${className || ''}`}
		>
			<div className="relative">
				{metaBlock
					? cloneElement(metaBlock, {
							className: `${
								metaBlock.props.className || ''
							} absolute top-0`,
							blur: metaBlock.props.blur !== false,
							metaBlockHref: metaBlock.props.metaBlockHref,
						})
					: null}
			</div>
			{contentToRender}
		</div>
	)
}
