import type {ReactSVGElement, ReactElement, ReactNode, JSX} from 'react'
import {useState, useEffect, createRef, cloneElement} from 'react'

interface DropdownDataItem {
	id: string | number
	title: string
	image: ReactSVGElement | ReactElement<{className?: string}>
}

interface CustomDropdownProps {
	defaultSelectText?: ReactNode
	dropdownLabel?: string
	className?: string
	dropdownData: DropdownDataItem[]
	onChange?: (data: DropdownDataItem) => void
	buttonClassName?: string
	itemClassName?: string
	itemTitleClassName?: string
	customDropdownIcon?: ReactNode
	listContainerClassName?: string
}

export const CustomDropdown = ({
	className = '',
	buttonClassName = '',
	itemClassName = '',
	listContainerClassName = '',
	customDropdownIcon = '',
	itemTitleClassName = '',
	dropdownLabel = 'Product Selector',
	defaultSelectText = 'Select a product',
	dropdownData,
	onChange = () => {
		/* Kept empty on purpose */
	},
}: CustomDropdownProps): JSX.Element => {
	let navTimer: number | NodeJS.Timeout = 0
	const dropdownMenuRef = createRef<HTMLUListElement>()
	const dropdownButtonRef = createRef<HTMLButtonElement>()
	const dropdownMenuContainerRef = createRef<HTMLDivElement>()
	const [selectedOption, setSelectedOption] = useState<{
		text: ReactNode
		option: DropdownDataItem | null
	}>({
		text: defaultSelectText,
		option: null,
	})

	const dropdownClick = (): void => {
		const dropdownEl = dropdownMenuRef.current

		if (!dropdownEl) return

		if (dropdownEl.classList.contains('block')) {
			dropdownEl.classList.remove('block')
			dropdownEl.classList.add('hidden')
		} else {
			dropdownEl.classList.remove('hidden')
			dropdownEl.classList.add('block')
			dropdownEl.focus()
		}
	}

	const updateSelectValue = (id: string | number): void => {
		const dropdownEl = dropdownMenuRef.current
		const selectedItem = dropdownData.find((x) => x.id === id)

		if (selectedItem) {
			setSelectedOption({
				text: selectedItem.title,
				option: selectedItem,
			})

			onChange(selectedItem)
		}

		dropdownEl?.classList.remove('block')
		dropdownEl?.classList.add('hidden')
	}

	function focusChildOption(): void {
		const dropdownEl = dropdownMenuRef.current
		const firstOption =
			dropdownEl?.querySelector<HTMLLIElement>('[role="option"]')
		const currentOption = dropdownEl?.querySelector<HTMLLIElement>(
			'[aria-selected="true"]'
		)
		if (!currentOption) {
			firstOption?.setAttribute('aria-selected', 'true')
			firstOption?.focus()
		} else {
			currentOption.focus()
		}
	}

	const navigateOptionsAndSelect = (
		e: React.KeyboardEvent<HTMLLIElement>,
		id: DropdownDataItem['id']
	): void => {
		const dropdownEl = dropdownMenuRef.current
		const currentOption = dropdownEl?.querySelector<HTMLLIElement>(
			'[aria-selected="true"]'
		)
		const previousElementSibling =
			currentOption?.previousElementSibling as HTMLLIElement | null

		const nextElementSibling =
			currentOption?.nextElementSibling as HTMLLIElement | null

		switch (e.key) {
			case 'ArrowUp':
				if (currentOption && previousElementSibling) {
					currentOption.setAttribute('aria-selected', 'false')
					previousElementSibling.setAttribute('aria-selected', 'true')
					previousElementSibling.focus()
				}
				break
			case 'ArrowDown':
				if (currentOption && nextElementSibling) {
					currentOption.setAttribute('aria-selected', 'false')
					nextElementSibling.setAttribute('aria-selected', 'true')
					nextElementSibling.focus()
				}
				break
			case 'Enter':
				updateSelectValue(id)
				break
			default:
			// do nothing
		}
	}

	const insideEvent = (): void => {
		clearTimeout(navTimer)
	}

	const outsideEvent = (): void => {
		navTimer = setTimeout(() => {
			const dropdownEl = dropdownMenuRef.current
			if (
				dropdownEl === null ||
				!dropdownEl.classList.contains('block')
			) {
				return
			}
			if (!dropdownEl.contains(document.activeElement)) {
				dropdownEl.classList.remove('block')
				dropdownEl.classList.add('hidden')
			}
		}, 0)
	}

	const keyboardEvent = (e: KeyboardEvent): void => {
		if (e.key === 'Escape') {
			const dropdownEl = dropdownMenuRef.current
			if (
				dropdownEl === null ||
				!dropdownEl.classList.contains('block')
			) {
				return
			}
			dropdownEl.classList.remove('block')
			dropdownEl.classList.add('hidden')
			dropdownButtonRef.current?.focus()
		}
	}

	useEffect(() => {
		const dropdownContainerEl = dropdownMenuContainerRef.current
		if (dropdownContainerEl === null) {
			return
		}
		document.addEventListener('keydown', keyboardEvent)
		dropdownContainerEl.addEventListener('focus', insideEvent, true)
		dropdownContainerEl.addEventListener('blur', outsideEvent, true)

		return () => {
			dropdownContainerEl.removeEventListener('focus', insideEvent, true)
			dropdownContainerEl.removeEventListener('blur', outsideEvent, true)
			document.removeEventListener('keydown', keyboardEvent)
		}
	})

	return (
		<div className={`relative ${className}`} ref={dropdownMenuContainerRef}>
			<span className="label-text top">{dropdownLabel}</span>
			<button
				className={`dropdown dropdown-blue-icon ${buttonClassName}`}
				onClick={dropdownClick}
				ref={dropdownButtonRef}
				type="button"
			>
				{selectedOption.text}
			</button>
			{customDropdownIcon || (
				<svg
					aria-hidden="true"
					className="pointer-events-none"
					focusable="false"
					height="46"
					viewBox="0 0 48 48"
					width="46"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect fill="" height="48" rx="24" width="48" />
					<path
						d="M7,10,18.426,21.426,29.853,10Z"
						fill="#fff"
						fillRule="evenodd"
						transform="translate(5.574 10.287)"
					/>
				</svg>
			)}

			<ul
				className={`max-h-3/4 absolute left-0 hidden w-full overflow-y-auto border border-gray-500 [&>li]:overflow-hidden [&>li]:border-b [&>li]:border-gray-500 [&>li]:bg-white [&>li]:last:border-0 ${listContainerClassName}`}
				onFocus={focusChildOption}
				ref={dropdownMenuRef}
				role="listbox"
				tabIndex={-1}
			>
				{dropdownData.map((data) => (
					<li
						aria-selected={data.id === selectedOption.option?.id}
						className={`flex cursor-pointer items-center p-4 hover:bg-gray-300 focus:bg-gray-300 ${itemClassName}`}
						data-id={data.id}
						key={data.id}
						onClick={() => {
							updateSelectValue(data.id)
						}}
						onKeyDown={(e) => {
							navigateOptionsAndSelect(e, data.id)
						}}
						role="option"
						tabIndex={-1}
					>
						{cloneElement(
							data.image as ReactElement<{className: string}>,
							{
								className: data.image.props.className ?? 'w-16',
							}
						)}
						<span className={itemTitleClassName || 'pl-6 text-xl'}>
							{data.title}
						</span>
					</li>
				))}
			</ul>
		</div>
	)
}
