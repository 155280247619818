import {useEffect, useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import {clsx} from 'clsx'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {loadImageFromKontentAI} from '@/imageLoader'
import {getContainerClass} from '../helpers'

export type ArticleHeaderBlockContentItem = IContentItem<{
	image: Elements.AssetsElement
	articleMobileImage: Elements.AssetsElement
	snippetContainerResize: Elements.MultipleChoiceElement
}>

export const ArticleHeaderBlock: Block<ArticleHeaderBlockContentItem> = ({
	block,
}) => {
	const image = block.elements.image[0]
	const mobileImage = block.elements.articleMobileImage[0]
	const containerSize = getContainerClass(
		block.elements.snippetContainerResize[0]?.codename ?? '',
		'container-wide'
	)
	const [headerState, setHeaderState] = useState<{
		articleheaderImage: TersedAsset | null
	}>({
		articleheaderImage: null,
	})

	const size = useWindowSize()
	useEffect(() => {
		if (size.isMobile) {
			if (mobileImage) {
				setHeaderState({
					articleheaderImage: mobileImage,
				})
			} else if (image) {
				setHeaderState({
					articleheaderImage: image,
				})
			} else {
				setHeaderState({
					articleheaderImage: null,
				})
			}
		} else {
			setHeaderState({
				articleheaderImage: image ?? null,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Causes infinite loop if either 'image' and 'mobileImage' are dependencies
	}, [image?.url, mobileImage?.url, size.isMobile])

	return (
		<div className={clsx(containerSize, `w-full md:flex`)}>
			<div className="relative z-20 overflow-hidden rounded-t-lg md:mb-0">
				{headerState.articleheaderImage ? (
					<Image
						alt={headerState.articleheaderImage.description ?? ''}
						height={headerState.articleheaderImage.height ?? 500}
						loader={loadImageFromKontentAI}
						src={headerState.articleheaderImage.url}
						width={
							headerState.articleheaderImage.width !== null &&
							headerState.articleheaderImage.width > 0
								? headerState.articleheaderImage.width
								: 750
						}
					/>
				) : null}
			</div>
		</div>
	)
}
