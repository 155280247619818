import {CardTabs} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {useRouter} from 'next/router'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {TextButton} from '@/_new-code/products/flexible-web-toolkit/components/button/text-button'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {Card} from '@/_new-code/products/flexible-web-toolkit/components/card'
import {CardImage} from '@/_new-code/products/flexible-web-toolkit/components/card/card-image'
import {Tab as CTab} from '@/_new-code/products/flexible-web-toolkit/components/tabs/tab'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import type {IconListContentItem} from '../icon-list'
import {IconListBlock} from '../icon-list'

type EnhancedProductDirectoryTabContentItem = IContentItem<{
	title: Elements.TextElement
	content: Elements.RichTextElement
	icons: Elements.LinkedItemsElement<IconListContentItem>
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	link: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export type EnhancedProductDirectoryCardContentItem = IContentItem<{
	title: Elements.TextElement
	logo: Elements.AssetsElement
	image: Elements.AssetsElement
	tabs: Elements.LinkedItemsElement<EnhancedProductDirectoryTabContentItem>
	whereToBuyUrl: Elements.LinkedItemsElement<CtaButtonContentItem>
	productLabelUrl: Elements.LinkedItemsElement<CtaButtonContentItem>
	issues: Elements.TaxonomyElement
	weightBands: Elements.TaxonomyElement
	species: Elements.TaxonomyElement
	productPlatform: Elements.TaxonomyElement
	productAdministration: Elements.TaxonomyElement
	productType: Elements.TaxonomyElement
	waysToPurchase: Elements.TaxonomyElement
	subspecies: Elements.TaxonomyElement
	lifeStage: Elements.TaxonomyElement
	brandHouse: Elements.TaxonomyElement
	hideFromLister: Elements.MultipleChoiceElement
	imageLink: Elements.TextElement
	listerOrderNumber: Elements.NumberElement
}>

export const EnhancedProductDirectoryCardBlock: Block<
	EnhancedProductDirectoryCardContentItem
> = ({block, ...context}) => {
	const {locale = '', push} = useRouter()
	return (
		<div className="flex h-full w-full justify-center">
			<Card className="relative h-full w-full border border-gray-700">
				<div className="flex w-full flex-col">
					{block.elements.logo[0] ? (
						<Image
							alt={block.elements.logo[0].description ?? ''}
							className="max-w-150px left-0 top-0 h-14 object-contain"
							height={block.elements.logo[0].height || 56}
							loader={loadImageFromKontentAI}
							src={block.elements.logo[0].url}
							width={block.elements.logo[0].width || 150}
						/>
					) : null}
					{block.elements.image[0] ? (
						<CardImage
							alt={block.elements.image[0].description ?? ''}
							className={clsx(
								!block.elements.logo[0] ? 'mt-20' : 'mt-5',
								'mb-1 h-40 justify-center object-contain align-middle'
							)}
							imageUrl={
								block.elements.imageLink
									? addLocalePrefix(
											block.elements.imageLink,
											locale
										)
									: undefined
							}
							src={block.elements.image[0].url}
						/>
					) : null}
				</div>
				<h3 className="mb-2 h-14 text-lg lg:text-xl">
					<span data-kontent-element-codename="title">
						{block.elements.title}
					</span>
				</h3>
				{block.elements.tabs.length > 0 ? (
					<CardTabs
						disableClickEvent={block.elements.tabs.length === 1}
						name={block.system.id}
					>
						{block.elements.tabs.map((tab) => (
							<CTab
								hideCloseBtn
								key={`${block.elements.title}-${tab.elements.title}`}
								tabPaddingClasses="p-4 pb-0 h-full"
								title={tab.elements.title}
							>
								<div
									className="flex h-full flex-col"
									data-kontent-item-id={tab.system.id}
								>
									<div>
										{tab.elements.icons.map((iconList) => (
											<IconListBlock
												block={iconList}
												className="-ml-5 mb-0 mt-0 pl-4"
												key={iconList.system.id}
												{...context}
											/>
										))}
									</div>
									<RichTextRenderer
										element={tab.elements.content}
										{...context}
									/>
									{tab.elements.link.map((link) => (
										<TextButton
											className="mb-3 w-full"
											href={link.elements.url}
											icon={
												ButtonIcons[
													link.elements.icon[0]
														?.name as keyof typeof ButtonIcons
												]
											}
											key={link.system.id}
											kontentProps={link}
											routerNavigateToPath={push}
											target={
												link.elements
													.openLinkInNewTab[0]
													?.codename === 'yes'
													? '_blank'
													: '_self'
											}
										>
											{link.elements.text}
										</TextButton>
									))}
									{tab.elements.button.map((button) => (
										<ReusableCTAButtonComponent
											block={button}
											className="mb-3 mt-auto w-full"
											key={button.system.id}
											{...context}
										/>
									))}
								</div>
							</CTab>
						))}
					</CardTabs>
				) : null}
				{block.elements.whereToBuyUrl.length > 0 ||
				block.elements.productLabelUrl.length > 0 ? (
					<div
						className={`mt-auto ${
							block.elements.whereToBuyUrl.length === 1 &&
							block.elements.productLabelUrl.length === 1
								? 'flex items-center justify-between'
								: 'flex flex-col space-y-2'
						}`}
					>
						{block.elements.whereToBuyUrl.map((button) => (
							<ReusableCTAButtonComponent
								block={button}
								className="w-full"
								key={button.system.id}
								{...context}
							/>
						))}
						{block.elements.productLabelUrl.map((button) => (
							<TextButton
								className="w-full text-center"
								href={button.elements.url}
								icon={
									button.elements.icon[0]?.name
										? ButtonIcons[
												button.elements.icon[0]
													?.name as keyof typeof ButtonIcons
											]
										: undefined
								}
								key={button.system.id}
								kontentProps={button}
								routerNavigateToPath={push}
								target={
									button.elements.openLinkInNewTab[0]
										?.codename === 'yes'
										? '_blank'
										: '_self'
								}
							>
								{button.elements.text}
							</TextButton>
						))}
					</div>
				) : null}
			</Card>
		</div>
	)
}
