import type {ReactElement, ReactNode} from 'react'
import React, {cloneElement, isValidElement, useRef, useState} from 'react'
import {Faq, FaqItem, YoutubeVideo} from '@elanco/component-library-v2'
import {EllipseButton} from '../button/ellipse-button'
import {Button} from '../button/button'

interface VideoBlockProps {
	backgroundColor?: BackgroundColor
	className?: string
	videoId: string
	posterFrameImageUrl?: string
	transcript?: boolean
	title?: ReactNode
	children?: React.ReactNode
	fullScreenIconOnLeft?: boolean
	icons?: ReactElement<IconsProps> | null
	noFullScreenButton?: boolean
	controls?: number
	fullscreenButtonAriaLabel?: string
	playButtonAriaLabel?: string
	playButtonText?: string
	closeButtonAriaLabel?: string
	closeButtonText?: string
	shareBlock?: React.ReactNode
	isPlaying?: () => void
	onEnd?: () => void
	onPause?: (data: boolean) => void
	playAt?: number
	isNotSkippable?: boolean
	backgroundColorClass?: string
	privacyMode?: boolean
}

interface YoutubeVideoRef {
	playVideo: () => void
}

interface IconProps {
	className?: string
	key?: string | number
}

interface IconsProps {
	children: IconElement | IconElement[]
}

type IconElement = ReactElement<IconProps>

type StyledIconsReturn = IconElement | IconElement[] | null

type BackgroundColor = string | undefined

export const VideoBlock = ({
	backgroundColor = undefined,
	className = '',
	videoId,
	posterFrameImageUrl = '',
	transcript,
	title = '',
	children = null,
	fullScreenIconOnLeft = false,
	icons = null,
	noFullScreenButton = false,
	controls = 0,
	fullscreenButtonAriaLabel = 'Open video in fullscreen',
	playButtonAriaLabel = 'Play video',
	playButtonText = 'Play',
	closeButtonAriaLabel = 'Close fullscreen modal',
	closeButtonText = 'Close',
	shareBlock = null,
	isPlaying = () => null,
	onEnd = () => null,
	onPause = () => null,
	playAt = 0,
	isNotSkippable = false,
	backgroundColorClass = 'bg-blue-transparent',
	privacyMode = false,
}: VideoBlockProps): React.JSX.Element => {
	const [showVideoModal, setShowVideoModal] = useState(false)
	const [hidePosterFrame, setHidePosterFrame] = useState(false)
	const [showCloseButton, setShowCloseButton] = useState(false)

	const youtubeVideoRef = useRef<YoutubeVideoRef>(null)

	const playButtonClickHandler = (): void => {
		setHidePosterFrame(true)
		youtubeVideoRef.current?.playVideo()
	}

	const pauseBtnHandler = (data: boolean): void => {
		if (!data) {
			setHidePosterFrame(false)
		}
		onPause(data)
	}

	const videoModalClickHandler = (): void => {
		setShowVideoModal(true)
		setShowCloseButton(true)
	}

	const closeButtonHandler = (): void => {
		setShowVideoModal(false)
		setShowCloseButton(false)
	}

	const styledIcons = (): StyledIconsReturn => {
		if (!icons || !isValidElement<IconsProps>(icons)) {
			return null
		}

		const iconChildren = icons.props.children
		const commonProps: IconProps = {
			className:
				'bg-blue-900 z-10 h-16 w-16 mb-2 flex justify-center items-center',
		}

		if (!Array.isArray(iconChildren)) {
			if (isValidElement<IconProps>(iconChildren)) {
				return cloneElement<IconProps>(iconChildren, {
					...commonProps,
					key: iconChildren.key ?? 'single-icon',
				})
			}
			return null
		}

		return iconChildren
			.filter((child): child is React.ReactElement<IconProps> =>
				isValidElement<IconProps>(child)
			)
			.map((element, index) =>
				cloneElement<IconProps>(element, {
					...commonProps,
					key: element.key ?? `icon-${index}`,
				})
			)
	}

	return (
		<div>
			<div
				className={`${className} ${backgroundColorClass} block w-full overflow-hidden rounded-lg shadow-secondary ${
					showVideoModal ? 'fixed inset-0 px-4 md:px-10' : 'relative'
				}`}
				style={{
					backgroundImage: hidePosterFrame
						? 'none'
						: `url('${posterFrameImageUrl}')`,
					backgroundColor:
						hidePosterFrame && !showVideoModal
							? '#000'
							: backgroundColor,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					paddingBottom: hidePosterFrame ? 0 : '56.25%',
					zIndex: showVideoModal ? 999 : undefined,
				}}
			>
				<div className="" style={{zIndex: '9'}}>
					{!noFullScreenButton && (
						<EllipseButton
							as="button"
							className={`absolute mt-4 ${
								fullScreenIconOnLeft
									? 'left-0 ml-4'
									: 'right-0 mr-4'
							} ${showCloseButton ? 'hidden' : ''}`}
							icon={
								<svg
									aria-hidden="true"
									focusable="false"
									height="15.07"
									viewBox="0 0 14.92 15.07"
									width="14.92"
									xmlns="http://www.w3.org/2000/svg"
								>
									<title>icon-expand</title>
									<path
										d="M7.18,9.43,4.1,12.51l2.56,2.56H0V8.41L2.56,11,5.64,7.89Z"
										style={{fill: '#fff'}}
										transform="translate(0)"
									/>
									<path
										d="M14.92,0V6.67L12.36,4.1,9.28,7.18,7.74,5.64l3.08-3.08L8.25,0Z"
										style={{fill: '#fff'}}
										transform="translate(0)"
									/>
								</svg>
							}
							iconSize="w-6 h-6"
							onClick={videoModalClickHandler}
							size="md"
						>
							{fullscreenButtonAriaLabel}
						</EllipseButton>
					)}
					<div
						className={`absolute right-0 z-10 text-center text-white ${
							icons ? 'mt-2' : 'mt-20'
						} mr-4`}
					>
						{icons ? styledIcons() : null}
						{shareBlock !== null && (
							<div className="relative">{shareBlock}</div>
						)}
					</div>
				</div>

				{videoId ? (
					<EllipseButton
						as="button"
						className={`absolute inset-0 z-10 m-auto ${
							hidePosterFrame ? 'hidden' : ''
						}`}
						icon={
							<svg
								className="pl-2"
								height="18.492"
								viewBox="0 0 15.954 18.492"
								width="15.954"
							>
								<path
									d="M8.381,1.493a1,1,0,0,1,1.73,0l7.511,12.96a1,1,0,0,1-.865,1.5H1.735a1,1,0,0,1-.865-1.5Z"
									transform="translate(15.954) rotate(90)"
								/>
							</svg>
						}
						iconSize="w-10 h-16"
						iconText={playButtonText}
						onClick={playButtonClickHandler}
						size="lg"
					>
						{playButtonAriaLabel}
					</EllipseButton>
				) : null}

				<Button
					as="button"
					className={`absolute ${
						fullScreenIconOnLeft ? 'left-0 ml-4' : 'right-0 mr-4'
					} z-10 mt-4 bg-blue-800 text-white hover:bg-blue-900 ${
						showCloseButton ? '' : 'hidden'
					}`}
					icon={
						<svg
							height="21.5"
							viewBox="0 0 21.499 21.5"
							width="21.499"
						>
							<path
								d="M-45.484,39.457l-1.973-1.973a1,1,0,0,1,0-1.415l7.07-7.07-7.069-7.07a1,1,0,0,1,0-1.414l1.973-1.973a1,1,0,0,1,1.414,0L-37,25.612l7.069-7.069a1,1,0,0,1,1.414,0l1.973,1.973a1,1,0,0,1,0,1.414L-33.613,29l7.07,7.07a1,1,0,0,1,0,1.414l-1.973,1.973a1,1,0,0,1-1.414,0L-37,32.386l-7.07,7.071a1,1,0,0,1-.707.293A1,1,0,0,1-45.484,39.457Z"
								transform="translate(47.75 -18.25)"
							/>
						</svg>
					}
					onClick={closeButtonHandler}
					reversed
					title={closeButtonAriaLabel}
				>
					{closeButtonText}
				</Button>

				{videoId ? (
					<div className="mx-auto flex h-full w-full max-w-screen-xl items-center">
						<YoutubeVideo
							className={`absolute mx-auto ${
								hidePosterFrame ? '' : 'hidden'
							} ${showVideoModal ? '' : ''}`}
							controls={controls}
							endVideoHandler={() => {
								onEnd()
							}}
							isNotSkippable={isNotSkippable}
							isPlaying={isPlaying}
							pauseHandler={pauseBtnHandler}
							playAt={playAt}
							privacyMode={privacyMode}
							ref={youtubeVideoRef}
							videoId={videoId}
						/>
					</div>
				) : null}
			</div>

			<div className={`mt-4 ${transcript ? '' : 'hidden'}`}>
				<div className="mt-6">
					<Faq className="overflow-hidden rounded-lg shadow-secondary">
						<FaqItem className="bg-white pt-5" title={title}>
							{children}
						</FaqItem>
					</Faq>
				</div>
			</div>
		</div>
	)
}
