/* eslint-disable @typescript-eslint/no-invalid-void-type -- TODO: fix this at some point */
import {useEffect, useRef, useState, type JSX} from 'react'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import {sendScreenSetEventToDataLayer} from '@/utils/analytics'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import type {
	OnErrorEvent,
	ErrorEventResponse,
	OnBeforeSubmitEvent,
	OnSubmitEvent,
	OnBeforeScreenLoadEvent,
	BeforeScreenLoadResponse,
	OnFieldChangedEvent,
	OnHideEvent,
	OnBeforeValidationEvent,
} from './models'

export type CdcGigyaContentItem = IContentItem<{
	screensetId: Elements.TextElement
	screenId: Elements.TextElement
	tabTitle: Elements.TextElement
	redirectPath: Elements.TextElement
}>

interface EventRegistration {
	onError?: (event: OnErrorEvent) => ErrorEventResponse | void
	onBeforeSubmit?: (event: OnBeforeSubmitEvent) => boolean
	onSubmit?: (event: OnSubmitEvent) => Promise<object> | object | void
	onAfterSubmit?: (event: OnAfterSubmitEvent) => void
	onBeforeScreenLoad?: (
		event: OnBeforeScreenLoadEvent
	) => BeforeScreenLoadResponse | boolean
	onAfterScreenLoad?: (event: OnAfterScreenLoadEvent) => void
	onFieldChanged?: (event: OnFieldChangedEvent) => void
	onHide?: (event: OnHideEvent) => void
	onBeforeValidation?: (
		event: OnBeforeValidationEvent
	) => Promise<object> | object | void
}

export const CdcGigyaBlock: ExtendedBlock<
	CdcGigyaContentItem,
	{
		eventRegistration?: EventRegistration
		holdRedirectUntilInterestApiFinished?: boolean
		campaignInterestSuccess?: boolean | null
	}
> = ({
	block,
	eventRegistration = {},
	holdRedirectUntilInterestApiFinished = false,
	campaignInterestSuccess = false,
}): JSX.Element => {
	// don't change the key on re-render
	const [loaded, setLoaded] = useState<boolean>(false)
	const setGigyaLoaded = (): void => {
		setLoaded(true)
	}
	const router = useRouter()
	const campaignInterestSuccessRef = useRef(campaignInterestSuccess)
	useEffect(() => {
		campaignInterestSuccessRef.current = campaignInterestSuccess
	}, [campaignInterestSuccess])

	useEffect(() => {
		const redirectToNextPage = (): void => {
			const query = new URLSearchParams(window.location.search)
			const queryStringRedirect = query.get('returnUrl')
			if (queryStringRedirect) {
				void router.push(queryStringRedirect)
			} else if (block.elements.redirectPath.length) {
				if (block.elements.redirectPath.endsWith('.pdf')) {
					window.open(block.elements.redirectPath, '_blank')
					return
				}

				void router.push(
					addLocalePrefix(
						block.elements.redirectPath,
						router.locale ?? ''
					)
				)
			}
		}
		// needed as we need to call onLoad, which we can't do in a <script>
		function loadScript(): void {
			const statusIndicator = document.getElementById('CDC-status')
			if (statusIndicator) {
				const handleStatusChange = (): void => {
					if ((statusIndicator as HTMLInputElement).value === 'Ready')
						setGigyaLoaded()
				}

				handleStatusChange()

				const observer = new MutationObserver(handleStatusChange)
				observer.observe(statusIndicator, {attributes: true})
			}
		}

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- inference is fine here
		const onError = (event: OnErrorEvent) => {
			if (typeof eventRegistration.onError === 'function') {
				const response = eventRegistration.onError(event)
				return response
			}
			return null
		}

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- inference is fine here
		const onBeforeSubmit = (event: OnBeforeSubmitEvent) => {
			if (typeof eventRegistration.onBeforeSubmit === 'function') {
				const response = eventRegistration.onBeforeSubmit(event)
				return response
			}
			return true
		}

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- inference is fine here
		const onSubmit = (event: OnSubmitEvent) => {
			if (typeof eventRegistration.onSubmit === 'function') {
				const response = eventRegistration.onSubmit(event)
				return response
			}
			return null
		}

		const onAfterSubmit = (event: OnAfterSubmitEvent): void => {
			const {response, form} = event
			sendScreenSetEventToDataLayer(response, form)

			if (typeof eventRegistration.onAfterSubmit === 'function') {
				eventRegistration.onAfterSubmit(event)
			}
		}

		const onBeforeScreenLoad = (
			event: OnBeforeScreenLoadEvent
		): boolean | BeforeScreenLoadResponse => {
			if (typeof eventRegistration.onBeforeScreenLoad === 'function') {
				const response = eventRegistration.onBeforeScreenLoad(event)
				return response
			}
			return true
		}

		const onAfterScreenLoad = (event: OnAfterScreenLoadEvent): void => {
			if (typeof eventRegistration.onAfterScreenLoad === 'function') {
				eventRegistration.onAfterScreenLoad(event)
			}
		}

		const onFieldChanged = (event: OnFieldChangedEvent): void => {
			if (typeof eventRegistration.onFieldChanged === 'function') {
				eventRegistration.onFieldChanged(event)
			}
		}

		const onHide = (event: OnHideEvent): void => {
			if (
				!holdRedirectUntilInterestApiFinished ||
				campaignInterestSuccessRef.current
			) {
				redirectToNextPage()
			}
			if (typeof eventRegistration.onHide === 'function') {
				eventRegistration.onHide(event)
			}
		}

		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- inference is fine here
		const onBeforeValidation = (event: OnBeforeValidationEvent) => {
			if (typeof eventRegistration.onBeforeValidation === 'function') {
				return eventRegistration.onBeforeValidation(event)
			}
			return {}
		}
		if (typeof window === 'undefined' || !loaded) {
			// loadScript sets loaded
			// runs on window, once
			loadScript()
			return
		}

		const eventListeners = {
			onHide,
			onAfterSubmit,
			...(typeof eventRegistration.onError === 'function'
				? {onError}
				: {}),
			...(typeof eventRegistration.onBeforeSubmit === 'function'
				? {onBeforeSubmit}
				: {}),
			...(typeof eventRegistration.onSubmit === 'function'
				? {onSubmit}
				: {}),
			...(typeof eventRegistration.onBeforeScreenLoad === 'function'
				? {onBeforeScreenLoad}
				: {}),
			...(typeof eventRegistration.onAfterScreenLoad === 'function'
				? {onAfterScreenLoad}
				: {}),
			...(typeof eventRegistration.onFieldChanged === 'function'
				? {onFieldChanged}
				: {}),
			...(typeof eventRegistration.onBeforeValidation === 'function'
				? {onBeforeValidation}
				: {}),
		}

		const screenSetConfig = {
			screenSet: block.elements.screensetId,
			startScreen: block.elements.screenId,
			containerID: block.system.id,
			...eventListeners,
		}

		// @ts-expect-error gigya is loaded by an external script
		gigya.accounts.showScreenSet(screenSetConfig)
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Used only required dependencies
	}, [loaded, router])

	return (
		<div data-kontent-element-codename="cdc_gigya" id={block.system.id} />
	)
}
