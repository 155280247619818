import {useEffect, useState, type JSX} from 'react'

export interface PreviewModeData {
	previewUrl: string
}

interface PreviewModeProps {
	isInPreviewMode: boolean
	previewData: PreviewModeData
}

/**
 * The PreviewModeBanner is used within the application root to:
 * 1. Indicate whether or not preview is active
 * 2. Provide a quick link to copy the current page URL
 */
export const PreviewModeBanner = (props: PreviewModeProps): JSX.Element => {
	const [origin, setOrigin] = useState('')
	const [path, setPath] = useState('')

	useEffect(() => {
		setOrigin(window.location.origin)
		setPath(window.location.pathname)
	}, [])

	const previewUrlForSlug = `http://${props.previewData.previewUrl}&slug=${path}`
	const shareText = 'Share URL'
	const [buttonText, setButtonText] = useState(shareText)

	useEffect(() => {
		const timer = setTimeout(() => {
			setButtonText(shareText)
		}, 2500)
		return () => {
			clearTimeout(timer)
		}
	}, [buttonText])

	const copyPreviewUrlToClipboard = (): void => {
		void navigator.clipboard.writeText(previewUrlForSlug)
		setButtonText('URL Copied To Clipboard')
	}

	return (
		<>
			{props.isInPreviewMode ? (
				<div
					className="banner_top fixed left-0 right-0 top-0 z-50 w-full bg-primary p-1 opacity-75"
					style={{
						height: '40px',
						maxHeight: '150px',
						zIndex: '9999',
					}}
				>
					<div className="flex h-full flex-row items-center justify-center">
						<div className="absolute right-0 px-5 py-0 text-center text-base text-white">
							<button
								className="mr-6 hidden h-7 items-center border-2 border-solid px-2 outline outline-1 outline-offset-2 md:inline-block"
								onClick={() => {
									copyPreviewUrlToClipboard()
								}}
								type="button"
							>
								<span className="pb-1.5">{buttonText}</span>
							</button>
							<a
								className="ml-2"
								href={`${origin}/api/preview/exit-preview`}
							>
								<strong>X</strong>
							</a>
						</div>

						<div className="flex h-full flex-col items-center justify-center md:flex-row">
							<div className="flex flex-col items-center sm:mr-5 sm:flex-row">
								<h4 className="my-0 uppercase text-white sm:mr-5">
									Preview Mode
								</h4>
							</div>
						</div>
					</div>
				</div>
			) : (
				// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: change to null when React types are fixed
				<></>
			)}
		</>
	)
}
