import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import type {MarkerClusterer} from '@googlemaps/markerclusterer'
import {useRouter} from 'next/router'
import {
	useDiseaseData,
	useNearbyDiseaseData,
} from '@/_new-code/services/disease-api/client'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import type {DISEASES} from '@/_new-code/services/disease-api/models'
import {MAP_TYPE} from '../../utils/constants'
import {HEATMAP} from './new-parasite-map.mac'
import type {
	AdvancedMarkerElementType,
	SearchLocation,
	UseParasiteMapReturn,
	UseParasiteMapProps,
} from './types'
import type {SearchHandler} from './search'
import {ParasiteMapController} from './new-parasite-map-controller'

export const useParasiteMap = ({
	block,
	error,
	setError,
	setShowSummaryText,
	setReportedCases,
	setLocationName,
	theme,
	mapRef,
}: UseParasiteMapProps): UseParasiteMapReturn => {
	const {
		layers,
		longitude,
		latitude,
		radius,
		heatmapOpacity,
		zoomLevel: initialZoomLevel,
		errorMessage: defaultErrorMessage,
		mapLegend,
		startDate,
		endDate,
		parasiteType,
		countyBorderColor,
		countyBorderWidth,
		stateBorderColor,
		stateBorderWidth,
		mapType,
		singleCaseTitle,
		singleCaseSubtitle,
		multipleCaseTitle,
		multipleCaseSubtitle,
		placeholderText,
	} = block.elements

	const [errorMessage, setErrorMessage] = useState<string>(
		defaultErrorMessage ||
			'An unexpected error occurred. Please try again later.'
	)
	const [showLoadingOverlay, setShowLoadingOverlay] = useState<boolean>(false)

	const [mapState, setMapState] = useState<google.maps.Map | null>(null)
	const [searchLocation, setSearchLocation] = useState<SearchLocation | null>(
		null
	)
	const [showMarkersAndPins, setShowMarkersAndPins] = useState<boolean>(true)

	const [renderedMarkers, setRenderedMarkers] = useState<
		AdvancedMarkerElementType[]
	>([])
	const markerCluster = useRef<MarkerClusterer | null>(null)
	const [searchHandler, setSearchHandler] = useState<
		SearchHandler | undefined
	>(undefined)
	const mapCircle = useRef<google.maps.Circle | null>(null)
	const radiusInMiles = radius ?? 50
	const router = useRouter()
	const countryCodes = router.locale
		? [router.locale, router.locale.toUpperCase()]
		: []

	const {isMobile} = useWindowSize()

	const {refetch} = useNearbyDiseaseData({
		lat: searchLocation?.lat ?? 0,
		lng: searchLocation?.lng ?? 0,
		selectedRadius: radiusInMiles || 0,
		diseases: parasiteType[0]?.codename.toUpperCase() ?? '',
	})
	const {
		isLoading: isLevel1Loading,
		isFetching: isLevel1Fetching,
		error: level1ApiError,
		data: level1Data,
	} = useDiseaseData({
		diseases: [
			parasiteType[0]?.codename.toUpperCase(),
		] as (keyof typeof DISEASES)[],
		groupBy: 'administrativeAreaLevel1',
		startDate: startDate ?? undefined,
		endDate: endDate ?? undefined,
		countryCodes,
	})

	const controller = useMemo(
		() =>
			new ParasiteMapController({
				block,
				isMobile: Boolean(isMobile),
				level1Data,
				setErrorMessage,
				setError,
				setShowLoadingOverlay,
				setShowSummaryText,
				setReportedCases,
				setLocationName,
				setSearchLocation,
				setSearchHandler,
				mapRef,
				setMapState,
			}),
		[
			block,
			isMobile,
			level1Data,
			mapRef,
			setError,
			setLocationName,
			setReportedCases,
			setShowSummaryText,
		]
	)

	const zoomToPlaceID = useCallback(
		(placeId: string) => {
			const isError = controller.zoomToPlace(placeId, Boolean(isMobile))
			setError(isError)
		},
		[controller, isMobile, setError]
	)

	const renderLocationMarker = useRef(async (): Promise<void> => {
		const {isError, data: fetchedCases} = await refetch()

		// Handle errors from refetch
		if (isError || !fetchedCases) {
			return
		}

		// Remove existing markers and pins
		if (markerCluster.current) {
			markerCluster.current.setMap(null)
		}
		renderedMarkers.forEach((marker) => (marker.map = null))
		setRenderedMarkers([])

		const nearbyCasesData = controller.sortCasesByPostCode(
			fetchedCases.data.data
		)

		const markers = await controller.createMarker(
			nearbyCasesData,
			mapState,
			singleCaseTitle,
			singleCaseSubtitle,
			multipleCaseTitle,
			multipleCaseSubtitle
		)

		if (markers.length === 0) {
			setReportedCases('0')
		}

		setRenderedMarkers(markers)
		const newMarkerCluster =
			await controller.createNewMarkerCluster(markers)
		newMarkerCluster.render()
		markerCluster.current = newMarkerCluster
	})

	useEffect(() => {
		void controller.renderMap()
	}, [
		heatmapOpacity,
		latitude,
		level1Data,
		longitude,
		mapLegend,
		setError,
		setLocationName,
		setReportedCases,
		setShowSummaryText,
		initialZoomLevel,
		zoomToPlaceID,
		stateBorderColor,
		stateBorderWidth,
		countyBorderColor,
		countyBorderWidth,
		layers,
		controller,
	])

	useEffect(() => {
		const parseResult = MAP_TYPE.safeParse(mapType[0]?.codename ?? '')
		const validatedMapType = parseResult.success ? parseResult.data : null
		if (mapState && searchLocation && validatedMapType !== HEATMAP) {
			if (mapCircle.current) {
				mapCircle.current.setMap(null)
				mapCircle.current = null
			}

			void controller.renderCircleAndMarkers(
				searchLocation,
				renderLocationMarker.current,
				radiusInMiles,
				mapType[0]?.codename ?? '',
				setShowMarkersAndPins,
				mapCircle
			)
		}

		const circle = mapCircle.current

		return () => {
			if (circle) {
				circle.setMap(null)
			}

			if (markerCluster.current) {
				markerCluster.current.setMap(null)
			}
			setRenderedMarkers([])
		}
	}, [controller, mapState, mapType, radiusInMiles, searchLocation])

	useEffect(() => {
		if (!showMarkersAndPins) {
			if (mapCircle.current) {
				mapCircle.current.setMap(null)
			}

			if (markerCluster.current) {
				markerCluster.current.setMap(null)
			}
			renderedMarkers.forEach((marker) => (marker.map = null))
		}

		if (showMarkersAndPins) {
			if (mapCircle.current) {
				mapCircle.current.setMap(mapState)
			}

			if (markerCluster.current) {
				markerCluster.current.setMap(mapState)
			}
			renderedMarkers.forEach((marker) => (marker.map = mapState))
		}
	}, [mapState, renderedMarkers, showMarkersAndPins])

	return {
		mapLegend,
		setError,
		isLevel1Fetching,
		isLevel1Loading,
		level1ApiError,
		error,
		setShowLoadingOverlay,
		showLoadingOverlay,
		searchHandler,
		errorMessage,
		theme,
		placeholderText,
	}
}
