import type {
	PropsWithChildren,
	ReactElement,
	ReactNode,
	ReactSVGElement,
	JSX,
} from 'react'
import {CountryDropdown} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import {EllipseButton} from '@/_new-code/products/flexible-web-toolkit/components/button/ellipse-button'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'

type Flag = ReactSVGElement | ReactElement<{className?: string}>

interface SecondaryDropdownCountry {
	label: string
	url: string
}
interface DropdownCountry {
	flag: Flag
	mainUrl: string
	name: string
	secondaryUrls: SecondaryDropdownCountry[]
}

interface DropdownCountryData {
	data: DropdownCountry[]
}

interface SocialFooterProps {
	brand: string
	className: string
	connectWithCompanyText: string
	connectWithText: string
	countryDropdownActiveCountry?: string
	countryDropdownCountryData?: DropdownCountryData | null
	countryDropdownDefaultValue?: string
	countryDropdownLabel?: string
	countryOnChangeEvent?: ((data: DropdownCountry, url: string) => void) | null
	emailSubscriptionForm: ReactNode
	facebookElancoUrl: string
	hasBorderTop?: boolean
	hideCountryDropdown?: boolean
	linkedInElancoUrl: string
	instagramElancoUrl: string
	socialIconColorClasses?: string
	twitterElancoUrl: string
	twitterToggle: boolean
	linkedInToggle: boolean
	instagramToggle: boolean
	facebookToggle: boolean
	youtubeToggle: boolean
	youtubeElancoUrl: string
}

export const SocialFooter = ({
	brand = 'Elanco',
	children = null,
	className = '',
	connectWithCompanyText = 'Connect with Elanco',
	connectWithText = 'Connect with',
	countryDropdownActiveCountry = '',
	countryDropdownCountryData = null,
	countryDropdownDefaultValue = 'Select a country',
	countryDropdownLabel = 'Country selector',
	countryOnChangeEvent = null,
	emailSubscriptionForm = null,
	facebookElancoUrl = 'https://www.facebook.com/elancoanimalhealth/',
	hasBorderTop = false,
	hideCountryDropdown = true,
	linkedInElancoUrl = 'https://www.linkedin.com/company/elanco',
	instagramElancoUrl = 'https://www.instagram.com/weareelanco/',
	socialIconColorClasses = '',
	twitterElancoUrl = 'https://twitter.com/Elanco',
	twitterToggle,
	linkedInToggle,
	instagramToggle,
	facebookToggle,
	youtubeToggle,
	youtubeElancoUrl = 'https://www.youtube.com/@ElancoAnimalHealthGlobal',
}: PropsWithChildren<SocialFooterProps>): JSX.Element => {
	// add spaces between words and capitalizes the first letter
	const result = brand.replace(/(?:[A-Z])/g, ' $&')
	const brandName = (result.charAt(0).toUpperCase() + result.slice(1)).trim()
	const hideUpdateForm = !emailSubscriptionForm

	return (
		<div
			className={clsx(
				'bg-main mx-auto border-b px-8',
				hasBorderTop ? 'border-t' : null,
				'md:pl-24 md:pr-16',
				className
			)}
		>
			<div className="flex flex-wrap items-start">
				<div className="flex flex-grow flex-wrap">
					<div className={clsx('mr-8', children ? '' : 'hidden')}>
						<p className="mt-4 font-bold">{`${connectWithText} ${brandName}`}</p>
						<div className="mb-1 flex items-center py-4 md:mr-10">
							{children}
						</div>
					</div>

					<div className="md:mr-10">
						<p className="mt-4 font-bold">
							{connectWithCompanyText}
						</p>
						<div className="mb-1 flex items-center py-4">
							{facebookToggle ? (
								<EllipseButton
									as="button"
									className={clsx(
										socialIconColorClasses,
										'mr-4'
									)}
									href={facebookElancoUrl}
									icon={ButtonIcons.Facebook}
									iconSize="h-8 w-8"
									noVariant={Boolean(socialIconColorClasses)}
									target="_blank"
								>
									Elanco&apos;s Facebook
								</EllipseButton>
							) : null}
							{twitterToggle ? (
								<EllipseButton
									as="button"
									className={clsx(
										socialIconColorClasses,
										'mr-4'
									)}
									href={twitterElancoUrl}
									icon={ButtonIcons.Twitter}
									iconSize="h-7 w-7"
									noVariant={Boolean(socialIconColorClasses)}
									target="_blank"
								>
									Elanco&apos;s Twitter
								</EllipseButton>
							) : null}
							{instagramToggle ? (
								<EllipseButton
									as="button"
									className={clsx(
										socialIconColorClasses,
										'mr-4'
									)}
									href={instagramElancoUrl}
									icon={ButtonIcons.Instagram}
									iconSize="h-6 w-6"
									noVariant={Boolean(socialIconColorClasses)}
									target="_blank"
								>
									Elanco&apos;s Instagram
								</EllipseButton>
							) : null}
							{linkedInToggle ? (
								<EllipseButton
									as="button"
									className={clsx(
										socialIconColorClasses,
										'mr-4'
									)}
									href={linkedInElancoUrl}
									icon={ButtonIcons.LinkedIn}
									iconSize="h-6 w-6"
									noVariant={Boolean(socialIconColorClasses)}
									target="_blank"
								>
									Elanco&apos;s Linkedin
								</EllipseButton>
							) : null}
							{youtubeToggle ? (
								<EllipseButton
									as="button"
									className={clsx(
										socialIconColorClasses,
										'mr-4'
									)}
									href={youtubeElancoUrl}
									icon={ButtonIcons.YouTube}
									iconSize="h-8 w-8"
									noVariant={Boolean(socialIconColorClasses)}
									target="_blank"
								>
									Elanco&apos;s YouTube
								</EllipseButton>
							) : null}
						</div>
					</div>

					{!hideUpdateForm && (
						<div
							className={clsx(
								'w-full max-w-sm pb-4',
								hideCountryDropdown
									? 'md:w-3/5 lg:w-2/5'
									: 'lg:w-1/2 xl:w-2/5'
							)}
						>
							{emailSubscriptionForm}
						</div>
					)}
				</div>

				{!hideCountryDropdown && (
					<div
						className={clsx(
							'mt-12 w-full pb-4',
							hideUpdateForm
								? 'md:w-3/5 lg:w-2/5'
								: 'lg:w-1/3 xl:w-2/5',
							'max-w-sm'
						)}
					>
						<CountryDropdown
							activeCountryOption={countryDropdownActiveCountry}
							defaultSelectedValue={countryDropdownDefaultValue}
							dropdownCountryData={countryDropdownCountryData}
							dropdownLabel={countryDropdownLabel}
							onChange={countryOnChangeEvent}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
