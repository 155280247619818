import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Visual} from '@/_new-code/products/flexible-web-toolkit/components/visual/visual'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import {RichTextRenderer} from '../../components/rich-text-renderer'

export type DistributorCardContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	image: Elements.AssetsElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	imageAlignment: Elements.MultipleChoiceElement
}>

export const DistributorCardBlock: Block<DistributorCardContentItem> = ({
	block: {
		elements: {
			button,
			description,
			image: [image],
			imageAlignment,
			title,
		},
	},
	...context
}) => {
	return (
		<Visual
			buttonSection={button.map((b) => (
				<ReusableCTAButtonComponent
					block={b}
					key={b.system.id}
					{...context}
				/>
			))}
			image={
				image ? (
					<Image
						alt={image.description ?? ''}
						height={
							image.height && image.height > 0
								? image.height
								: 300
						}
						loader={loadImageFromKontentAI}
						src={image.url}
						width={
							image.width && image.width > 0 ? image.width : 500
						}
					/>
				) : null
			}
			infoSection={
				<RichTextRenderer
					className="py-6"
					element={description}
					{...context}
				/>
			}
			reversed={imageAlignment[0]?.codename === 'left'}
			title={<h3>{title}</h3>}
		/>
	)
}
