import {useState, useEffect} from 'react'
import {ShareBlock} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {replaceString, textChecker} from '@/utils/rich-text-helper'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {BlogHeading as Blog} from '@/_new-code/products/flexible-web-toolkit/components/blog-heading'

export type BlogHeadingContentItem = IContentItem<{
	title: Elements.TextElement
	author: Elements.TextElement
	date: Elements.TextElement
	displayText: Elements.RichTextElement
	authorImage: Elements.AssetsElement
	facebookUrl: Elements.TextElement
	linkedinUrl: Elements.TextElement
	twitterUrl: Elements.TextElement
	mailSubject: Elements.TextElement
	mailBody: Elements.TextElement
	shareTitle: Elements.TextElement
	maillinkToggle: Elements.MultipleChoiceElement
	showOnHover: Elements.MultipleChoiceElement
	shareBlockTitle: Elements.TextElement
}>

export const BlogHeadingBlock: Block<BlogHeadingContentItem> = ({
	block: {
		elements: {
			author,
			authorImage,
			date,
			displayText,
			facebookUrl,
			linkedinUrl,
			twitterUrl,
			mailBody,
			mailSubject,
			maillinkToggle,
			shareBlockTitle,
			showOnHover,
			title,
		},
	},
}) => {
	let defaultDisplayText = ''
	if (author && date) {
		defaultDisplayText =
			'<p>Posted by <strong>{author}</strong> on <strong>{date}</strong></p>'
	} else if (author) {
		defaultDisplayText = '<p>Posted by <strong>{author}</strong>'
	} else if (date) {
		defaultDisplayText = '<p>Posted on <strong>{date}</strong></p>'
	}

	const display = textChecker(displayText.value, defaultDisplayText)
	const html = replaceString(display, [
		['{author}', author],
		['{date}', date],
	])

	const hasAuthor = Boolean(html) || Boolean(authorImage.length)

	const [articleLink, setArticleLink] = useState('')

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setArticleLink(window.location.href)
		}
	}, [])

	return (
		<Blog
			authorImage={
				Boolean(authorImage.length) && authorImage[0]?.url ? (
					<div className="relative h-full w-full">
						<Image
							alt={authorImage[0]?.description ?? ''}
							fill
							loader={loadImageFromKontentAI}
							src={authorImage[0]?.url}
						/>
					</div>
				) : null
			}
			className="w-full"
			data-kontent-element-codename="blog_heading"
			postedByText={
				Boolean(html) && (
					<div
						dangerouslySetInnerHTML={{__html: html}}
						data-kontent-element-codename="display_text"
					/>
				)
			}
			shareBlock={
				<ShareBlock
					facebookShareUrl={facebookUrl}
					hasAuthor={hasAuthor}
					linkedinShareUrl={linkedinUrl}
					mailBody={`${mailBody}\n\n${articleLink}`}
					mailLink={articleLink}
					mailLinkToggle={maillinkToggle[0]?.codename ?? 'no'}
					mailSubject={mailSubject}
					sharetitle={shareBlockTitle}
					showOnHover={showOnHover[0]?.codename === 'yes'}
					title={shareBlockTitle}
					twitterShareUrl={twitterUrl}
				/>
			}
			title={<span data-kontent-element-codename="title">{title}</span>}
		/>
	)
}
