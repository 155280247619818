import type {ChangeEvent} from 'react'
import {useState, useRef} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import {pushToDataLayer} from '@/utils/analytics'

export type ImageComparisonContentItem = IContentItem<{
	/** A number between 0 and 100 representing the position of the initial slider, where 0 is all the way to the left and 100 is all the way to the right */
	initialExposure: Elements.NumberElement

	/** The image to compare, which will display on the left when the slider is dragged */
	leftImage: Elements.AssetsElement
	/** The image to compare, which will display on the right when the slider is dragged */
	rightImage: Elements.AssetsElement

	/** The text that will appear in the top left of the slider. */
	leftLegendText: Elements.RichTextElement
	/** The text that will appear in the top right of the slider. */
	rightLegendText: Elements.RichTextElement

	/** The padding that should be added to the component */
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const ImageComparisonBlock: Block<ImageComparisonContentItem> = ({
	block: {
		elements: {
			initialExposure,
			leftImage,
			rightImage,
			leftLegendText,
			rightLegendText,
			snippetSpacingSpacing,
		},
		system: {codename},
	},
	...context
}) => {
	const defaultExposure = initialExposure ?? 50
	const [exposure, setExposure] = useState(defaultExposure)
	const [lastDataLayerEventTriggeredAt, setLastDataLayerEventTriggeredAt] =
		useState(defaultExposure)
	const inputRef = useRef<HTMLInputElement>(null)

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		// Handle the actual slider mechanics
		const newExposure = parseInt(event.target.value, 10)
		setExposure(newExposure)

		// In Google Analytics, we want to trigger an interaction event for every +/- 5% moved
		const delta = Math.abs(newExposure - lastDataLayerEventTriggeredAt)
		if (delta >= 5) {
			// Round value to nearest 5
			const newBreakpoint = Math.ceil(newExposure / 5) * 5

			pushToDataLayer({
				event: 'slider_percentage',
				percentage: newBreakpoint,
				slider_codename: codename,
			})
			setLastDataLayerEventTriggeredAt(newBreakpoint)
		}
	}

	const spacingChoice = spacing[
		snippetSpacingSpacing[0]?.codename as keyof typeof spacing
	] as keyof typeof spacing | null

	const componentSpacing = !spacingChoice ? spacing.none : spacingChoice

	return (
		<div className={componentSpacing}>
			<div className="flex w-full justify-between">
				{leftLegendText.value !== '<p></p>' ? (
					<RichTextRenderer
						className="max-w-[30%] break-all bg-primary px-6 py-2 text-white *:*:!mb-0"
						data-kontent-element-codename="left_legend_text"
						element={leftLegendText}
						{...context}
					/>
				) : null}
				{rightLegendText.value !== '<p></p>' ? (
					<RichTextRenderer
						className="max-w-[30%] break-all bg-primary px-6 py-2 text-right text-white *:*:!mb-0"
						data-kontent-element-codename="right_legend_text"
						element={rightLegendText}
						{...context}
					/>
				) : null}
			</div>
			<div className="relative overflow-hidden">
				{leftImage[0] ? (
					<Image
						alt={leftImage[0].description ?? ''}
						className="h-auto w-full"
						data-kontent-element-codename="left_image"
						height={leftImage[0].height ?? 100}
						loader={loadImageFromKontentAI}
						src={leftImage[0].url}
						width={leftImage[0].width ?? 100}
					/>
				) : null}
				{rightImage[0] ? (
					<Image
						alt={rightImage[0].description ?? ''}
						className="absolute top-0 h-full w-full"
						data-kontent-element-codename="right_image"
						fill
						loader={loadImageFromKontentAI}
						src={rightImage[0].url}
						style={{
							clipPath: `polygon(${exposure}% 0, 100% 0, 100% 100%, ${exposure}% 100%)`,
						}}
					/>
				) : null}
				<label className="absolute bottom-0 left-0 right-0 top-0 flex items-stretch">
					<span className="sr-only">
						Adjust the visibility of two overlaid images. A value of
						0 makes the first image completely shown and the second
						image fully hidden. A value of 100 makes the first image
						fully hidden and the second image fully shown. A value
						of 50 results in both images being equally visible at
						50% opacity.
					</span>
					<input
						className="image-comparison m-0 w-full cursor-col-resize appearance-none border-0 !bg-transparent p-0 hover:!bg-transparent active:!bg-transparent"
						max={100}
						min={0}
						onChange={handleInputChange}
						ref={inputRef}
						step={0.01}
						type="range"
						value={exposure}
					/>
				</label>
			</div>
		</div>
	)
}
