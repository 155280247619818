import {
	HeaderBlockWithCarousel,
	HeaderBlockCarouselSlide,
} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {useState, useEffect} from 'react'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

type HeaderBlockCarouselSlideContentItem = IContentItem<{
	heading: Elements.TextElement
	subheading: Elements.TextElement
	headingUrl: Elements.TextElement
	imgUrl: Elements.TextElement
	image: Elements.AssetsElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	isContentOnTopOfImage: Elements.MultipleChoiceElement
	mobileImage: Elements.AssetsElement
}>

export type HeaderBlockCarouselContentItem = IContentItem<{
	headerBlockCarouselSlides: Elements.LinkedItemsElement<HeaderBlockCarouselSlideContentItem>
}>

export const HeaderBlockCarouselBlock: Block<
	HeaderBlockCarouselContentItem
> = ({block, ...context}) => {
	const {locale} = useRouter()
	const size = useWindowSize() as {isMobile: boolean}

	const [slideImages, setSlideImages] = useState<(TersedAsset | null)[]>(
		block.elements.headerBlockCarouselSlides.map(
			(slide) => slide.elements.image[0] ?? null
		)
	)

	useEffect(() => {
		setSlideImages(
			block.elements.headerBlockCarouselSlides.map((slide) => {
				const mobileImage = slide.elements.mobileImage[0] ?? null
				const desktopImage = slide.elements.image[0] ?? null
				return size.isMobile && mobileImage ? mobileImage : desktopImage
			})
		)
	}, [size.isMobile, block.elements.headerBlockCarouselSlides])

	return (
		<HeaderBlockWithCarousel>
			{block.elements.headerBlockCarouselSlides.map((slide, index) => {
				const isContentOnTopOfImage =
					slide.elements.isContentOnTopOfImage[0]?.codename === 'yes'
				const button = slide.elements.button[0]
				const slideImage = slideImages[index]

				return (
					<HeaderBlockCarouselSlide
						button={
							button ? (
								<ReusableCTAButtonComponent
									block={button}
									className={
										isContentOnTopOfImage
											? 'w-max lg:w-2/3'
											: undefined
									}
									key={button.system.id}
									{...context}
								/>
							) : null
						}
						heading={slide.elements.heading}
						headingUrl={addLocalePrefix(
							slide.elements.headingUrl || '/',
							locale ?? ''
						)}
						image={
							slideImage ? (
								<Image
									alt={slideImage.description ?? ''}
									height={slideImage.height ?? 552}
									loader={loadImageFromKontentAI}
									priority
									src={slideImage.url}
									width={slideImage.width ?? 1988}
								/>
							) : null
						}
						isContentOnTopOfImage={isContentOnTopOfImage}
						key={slide.system.id}
						subheading={slide.elements.subheading}
						url={addLocalePrefix(
							slide.elements.imgUrl || '/',
							locale ?? ''
						)}
					/>
				)
			})}
		</HeaderBlockWithCarousel>
	)
}
